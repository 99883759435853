import get from 'lodash/get';
import { Icon } from 'components/common';
import isEqual from 'react-fast-compare';
import React, {
  useMemo, useCallback, useRef, useState,
  useEffect,
} from 'react';
import { useSelector } from 'react-redux';
import useResetParams from 'hooks/useResetParams';
import { addOptionToIncludedFilters, removeOptionFromFilters } from 'data/actions/search';
import { getFiltersSelector, getFilterOptionsSelector, getFilterAutocompleteOptionsSelector } from 'data/selectors/search';
import {
  SEARCH_FILTER_TYPES, multipleValProps, COMPANY_SIZE, COMPANY_INDUSTRY,
} from 'data/types/search.filters.types';
import useOnClickOutside from 'helpers/clickOutside';
import {
  FilterSuggestOptions,
  FilterOptionItem,
  ControlsContainer,
  FilterTree,
  FilterTreeNode,
  AbsoluteWrapper,
  HeaderContainer,
  TreeContainer,
  NotFoundOptions,
  OptionListWrapper,
  FilterOptionItemsWrapper,
} from '../../../sidebar/styles';

const SuggestOptions = ({
  property, processUniqueStrategy, includedOp, splittable, clearInput, renderOption, checkboxes, type, showTree, updateOnly, radioGroup, input, setShowTree, dataRef, focusInput, hideDropdownWhenDefaultListEmpty, wasFirstInputChanged, inputWithDataRef, disableNotFoundDropdown,
}) => {
  const filtersSelector = getFiltersSelector(property);
  const optionsSelector = getFilterOptionsSelector(property);
  const autoCompleteOptionsSelector = getFilterAutocompleteOptionsSelector(property);
  const filters = useSelector(filtersSelector);
  const options = useSelector(optionsSelector);
  const autocompleteOptions = useSelector(autoCompleteOptionsSelector);
  const removeFilter = useResetParams((element) => removeOptionFromFilters({ property, element }));
  const ref = useRef();
  const [itemsToExpend, setItemsToExpand] = useState([]);
  const [scrollTop, setScrollTop] = useState(document.querySelector('.search_filters')?.scrollTop || 0);

  useEffect(() => {
    const searchFilters = document.querySelector('.search_filters');
    function changeScrollTop(event) {
      setScrollTop(event.target.scrollTop);
    }
    changeScrollTop({ target: searchFilters });
    if (searchFilters) {
      searchFilters.addEventListener('scroll', changeScrollTop);
    }
    return () => searchFilters.removeEventListener('scroll', changeScrollTop);
  }, []);

  const renderTreeNodes = useCallback((data) => data?.map((item) => {
    if (item.children) {
      return (
        <FilterTreeNode title={item.title} key={item.key} dataRef={item}>
          {renderTreeNodes(item.children)}
        </FilterTreeNode>
      );
    }
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <FilterTreeNode key={item.key} {...item} />;
  }), []);

  const uniqOptions = useMemo(() => {
    const dataToProcess = autocompleteOptions || options;

    if (processUniqueStrategy) {
      return processUniqueStrategy(dataToProcess);
    }
    return dataToProcess;
  }, [options, processUniqueStrategy, autocompleteOptions]);

  const resetParams = useResetParams((element) => {
    if (type !== SEARCH_FILTER_TYPES.TREE) {
      clearInput();
    }
    focusInput();
    return addOptionToIncludedFilters({
      property, includedOp, element, splittable, checkboxes, updateOnly,
    });
  });
  const includedFilters = get(filters, 'included.value', []) ?? [];
  const excludedFilters = get(filters, 'excluded.value', []) ?? [];

  const handleExpand = useCallback((_, e) => {
    const selectedItemChildren = get(e, 'node.props.children');
    const categoryKey = get(e, 'node.props.dataRef.key');

    if (inputWithDataRef.current) return;

    if (selectedItemChildren && categoryKey) {
      setItemsToExpand((prevExpandedKeys) => {
        const updatedKeys = [...prevExpandedKeys];
        const keyIndex = updatedKeys.findIndex((key) => key === categoryKey);

        if (keyIndex > -1) {
          updatedKeys.splice(keyIndex, 1);
        } else {
          updatedKeys.push(categoryKey);
        }
        return updatedKeys;
      });
    }
  }, [inputWithDataRef]);

  const handleTreeSelect = useCallback((_, e) => {
    const isSelect = get(e, 'event') === 'select';
    const selectedItemChildren = get(e, 'node.props.children');
    const categoryKeyItem = get(e, 'node.props.dataRef.key');

    if (selectedItemChildren && categoryKeyItem && isSelect) {
      handleExpand(null, e);
      return;
    }
    const selectedOptions = [...includedFilters, ...excludedFilters];
    const optionTitle = e?.node?.props?.title;
    const children = e?.node?.props?.children;
    const isChecked = e?.node?.props?.checked;
    let childrenData = [];
    let optionToDrop;
    let foundCategory;

    if (children?.length) {
      childrenData = children.map((child) => child?.props?.title);

      if (childrenData.length) {
        foundCategory = options.find((option) => option.name === optionTitle);

        if (foundCategory) {
          childrenData = foundCategory?.children?.map((option) => get(option, 'name', option));
        }
      }
      selectedOptions.filter((selectedOption) => selectedOption?.category === optionTitle || !selectedOption?.category).some((selectedOption) => {
        const selectedOptionLabel = (selectedOption?.printedData ?? selectedOption);

        if (selectedOptionLabel === optionTitle && isChecked) {
          optionToDrop = { value: selectedOptionLabel, isCategory: true };
          return optionToDrop;
        }
        return false;
      });
    } else {
      const [categoryKey] = e?.node?.props?.eventKey?.split('_');
      selectedOptions.some((selectedOption) => {
        const foundedSubOption = Array.isArray(selectedOption?.matching) ? get(selectedOption, 'matching', []).some((subOption) => (subOption?.name ?? subOption) === optionTitle)
          && selectedOption?.printedData === categoryKey : null;

        if (foundedSubOption || selectedOption?.printedData === optionTitle) {
          optionToDrop = optionTitle;
          return optionToDrop;
        }
        return false;
      });
    }

    let category = options.find((option) => option.name === optionTitle);

    if (category && !optionToDrop) {
      if (foundCategory) {
        category = foundCategory;
      }
      const childrenLength = category.children.length;
      const checkedChildren = [];

      const incrementChildCheck = (option) => {
        const existedChild = category.children.find((categoryOption) => get(categoryOption, 'name', categoryOption) === option.printedData);

        if (existedChild) checkedChildren.push(get(existedChild, 'name', existedChild));
      };

      get(filters, 'included.value', []).forEach(incrementChildCheck);
      get(filters, 'excluded.value', []).forEach(incrementChildCheck);
      optionToDrop = checkedChildren.length === childrenLength && checkedChildren;
    }
    // for delete by category click with search data in input
    if (!optionToDrop && isChecked && children?.length && foundCategory && childrenData?.length !== children?.length) {
      optionToDrop = children.map((child) => child?.props?.title);
    }
    if (optionToDrop) {
      removeFilter(optionToDrop);
    } else {
      resetParams(childrenData?.length ? childrenData : optionTitle);
    }
  }, [excludedFilters, filters, includedFilters, options, removeFilter, resetParams, handleExpand]);

  const handleListSelect = useCallback((_, e) => {
    const selectedOptions = [...includedFilters, ...excludedFilters];
    let optionTitle = e?.node?.props?.title;

    const existedOption = selectedOptions.some((option) => {
      let result = get(option, 'label', option) === optionTitle;

      if (!result && Array.isArray(option)) {
        result = option.some((item) => item === optionTitle);
      }
      return result;
    });

    if (multipleValProps.some((prop) => prop === property)) {
      const optionToProcess = uniqOptions.find((prop) => prop?.label === optionTitle);

      if (optionToProcess?.value?.length) {
        optionTitle = optionToProcess.value;
      }
    }
    return existedOption ? removeFilter(optionTitle) : resetParams(optionTitle);
  }, [includedFilters, excludedFilters, removeFilter, resetParams, uniqOptions, property]);

  const dataForRenderTreeType = useCallback(() => {
    let dataToRender = [];
    const selectedOptions = [...includedFilters, ...excludedFilters];
    const keyArray = [];

    if (uniqOptions?.[0]?.children?.length) {
      dataToRender = uniqOptions.map((item) => {
        const { name, children } = item;
        return {
          title: name,
          key: name,
          children: children.map((subcategoryData) => {
            const subcategoryName = get(subcategoryData, 'name', subcategoryData);
            const createdKey = `${name}_${subcategoryName}`;
            keyArray.push(createdKey);
            return ({ title: subcategoryName, key: createdKey });
          }),
        };
      });
    }
    const copyOfSelectedOptions = JSON.parse(JSON.stringify(selectedOptions));
    const treeSelectedOptions = keyArray.filter((optionKey) => {
      const [, keyDataToCompare] = optionKey.split('_');

      let childIndexToRemove = -1;
      const foundRightOptionKey = copyOfSelectedOptions.findIndex((option) => {
        const { printedData, matching } = option ?? {};

        if (Array.isArray(matching)) {
          const subMatchingIndex = matching.findIndex((matchingItem) => (matchingItem?.name ?? matchingItem) === keyDataToCompare);

          if (subMatchingIndex > -1) childIndexToRemove = subMatchingIndex;
          return subMatchingIndex > -1;
        }
        return (printedData ?? option) === keyDataToCompare;
      });
      let foundRightOption;

      if (foundRightOptionKey !== -1) {
        if (childIndexToRemove > -1) {
          [foundRightOption] = copyOfSelectedOptions[foundRightOptionKey].matching.splice(childIndexToRemove, 1) ?? [];
        } else {
          [foundRightOption] = copyOfSelectedOptions.splice(foundRightOptionKey, 1) ?? [];
        }
      }
      return foundRightOption;
    });

    return { treeSelectedOptions, dataToRender };
  }, [excludedFilters, includedFilters, uniqOptions]);

  const dataForRenderListType = useCallback(() => {
    let dataToRender = [];
    const keyArray = [];
    const selectedOptions = [...includedFilters, ...excludedFilters];
    uniqOptions.filter((option) => !selectedOptions.some((el) => isEqual(el, get(option, 'value', option))));

    dataToRender = uniqOptions.map((item) => {
      const name = get(item, 'label', item);
      keyArray.push(name);
      return {
        title: name,
        key: name,
      };
    });

    const treeSelectedOptions = keyArray.filter((optionKey) => selectedOptions.some((option) => (Array.isArray(option) ? get(option, '[0]', option) === optionKey : option === optionKey)));
    return { dataToRender, treeSelectedOptions };
  }, [excludedFilters, includedFilters, uniqOptions]);

  const optionsList = useMemo(() => {
    if ([SEARCH_FILTER_TYPES.TREE, SEARCH_FILTER_TYPES.LIST].includes(type) && input) {
      let notFoundItem = wasFirstInputChanged && hideDropdownWhenDefaultListEmpty ? (<NotFoundOptions>Not found</NotFoundOptions>) : null;

      let content = renderOption ? uniqOptions.filter((option) => ![...includedFilters, ...excludedFilters].some((el) => isEqual(el, get(option, 'value', option))))
        .map((element, index) => {
          const value = get(element, 'value', element);
          const label = get(element, 'label', element);
          return (
            <OptionListWrapper
              key={`${+index}_suggest`}
              onClick={() => resetParams(value)}
            >
              {renderOption ? renderOption(element) : label}
            </OptionListWrapper>
          );
        }) : null;

      if (!content?.length && renderOption) {
        if (disableNotFoundDropdown) {
          notFoundItem = null;
        }
        content = notFoundItem;
      }

      if (!hideDropdownWhenDefaultListEmpty) {
        notFoundItem = <NotFoundOptions>Not found</NotFoundOptions>;
      }

      if (notFoundItem && disableNotFoundDropdown) {
        notFoundItem = null;
      }

      if (!content?.length && !renderOption) {
        const expandedOptions = inputWithDataRef.current ? uniqOptions.map((item) => item.name) : itemsToExpend;
        const isTreeType = SEARCH_FILTER_TYPES.TREE === type;
        const { treeSelectedOptions, dataToRender } = isTreeType ? dataForRenderTreeType() : dataForRenderListType();
        const checkedKeys = treeSelectedOptions;
        const renderedData = renderTreeNodes(dataToRender);

        const onCheckFn = isTreeType ? handleTreeSelect : handleListSelect;
        content = radioGroup ? (
          <>
            <HeaderContainer>{radioGroup}</HeaderContainer>
            <TreeContainer>
              {renderedData.length ? (
                <FilterTree
                  checkable
                  selectable
                  switcherIcon={<Icon size={20} type="ic-chevron-right" />}
                  onCheck={onCheckFn}
                  onSelect={onCheckFn}
                  checkedKeys={checkedKeys}
                  expandedKeys={expandedOptions}
                  onExpand={handleExpand}
                >
                  {renderedData}
                </FilterTree>
              ) : <NotFoundOptions>Not found</NotFoundOptions>}
            </TreeContainer>
          </>
        ) : (
          <>
            {renderedData.length ? (
              <FilterTree
                checkable
                selectable
                switcherIcon={<Icon size={20} type="ic-chevron-right" />}
                onCheck={onCheckFn}
                onSelect={onCheckFn}
                checkedKeys={checkedKeys}
                expandedKeys={expandedOptions}
                onExpand={handleExpand}
              >
                {renderedData}
              </FilterTree>
            ) : (
              notFoundItem
            )}
          </>
        );
      }
      const parentNode = document.getElementById('check');

      return ([
        <AbsoluteWrapper
          ref={ref}
          key={property}
          top={parentNode?.offsetTop - scrollTop}
          showTree={showTree}
          onClick={() => {
            focusInput(false);
          }}
        >
          {content}
        </AbsoluteWrapper>,
      ]);
    }

    return [
      <FilterOptionItemsWrapper key={property}>
        {uniqOptions.filter((option) => ![...includedFilters, ...excludedFilters].some((el) => {
          let dataToCompare = get(option, 'value', option);

          if (Array.isArray(dataToCompare?.[0])) {
            [dataToCompare] = dataToCompare;
          }
          return isEqual(el, dataToCompare);
        }))
          .map((element, index) => {
            const value = get(element, 'value', element);
            let label = get(element, 'label', element);

            if (property === COMPANY_SIZE) {
              const [, max] = label.split('-');

              if (!max) {
                label = `${label} and more`;
              }
            }

            return (
              <FilterOptionItem
                key={`${+index}_suggest`}
                onClick={() => resetParams(value)}
              >
                {renderOption ? renderOption(element) : label}
              </FilterOptionItem>
            );
          })}
      </FilterOptionItemsWrapper>,
    ];
  }, [disableNotFoundDropdown, inputWithDataRef, wasFirstInputChanged, hideDropdownWhenDefaultListEmpty, focusInput, handleExpand, input, uniqOptions, includedFilters, excludedFilters, resetParams, renderOption, type, renderTreeNodes, property, showTree, handleTreeSelect, dataForRenderTreeType, dataForRenderListType, handleListSelect, radioGroup, itemsToExpend, scrollTop]);

  useOnClickOutside([dataRef], () => {
    setShowTree(false);
  });

  return (
    <ControlsContainer
      radioGroupExists={radioGroup}
      isIndustry={COMPANY_INDUSTRY === property}
      isList={SEARCH_FILTER_TYPES.LIST === type}
      forceExpanded={inputWithDataRef.current}
    >
      <FilterSuggestOptions>
        {
          optionsList.length ? (
            <>
              <span className="tour-filter-options" />
              {optionsList}
            </>
          ) : null
        }
      </FilterSuggestOptions>
    </ControlsContainer>
  );
};

export default SuggestOptions;
